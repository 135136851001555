import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Button } from "rebass";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "styling-with-tiaui"
    }}>{`Styling with TiaUI`}</h1>
    <p>{`TiaUI is a design system based on `}<a parentName="p" {...{
        "href": "https://theme-ui.com/"
      }}>{`ThemeUI`}</a>{`.`}</p>
    <p>{`We treat it as a library with its own dev cycle, releases, and semver. You can find it on github 👉 `}<a parentName="p" {...{
        "href": "https://github.com/AskTia/tia-ui"
      }}>{`https://github.com/AskTia/tia-ui`}</a></p>
    <p>{`If you ever need to look up a component, its storybook is published on Chromatic 👉 `}<a parentName="p" {...{
        "href": "https://5efa84bae8e40500223e8b9e-bnaqzlpuwp.chromatic.com/"
      }}>{`https://5efa84bae8e40500223e8b9e-bnaqzlpuwp.chromatic.com/`}</a></p>
    <h2 {...{
      "id": "the-basics"
    }}>{`The basics`}</h2>
    <p>{`TiaUI is an extension of ThemeUI. Everything ThemeUI can do, TiaUI can do. You can use all the same APIs. Our library is preconfigured to spit out interfaces that follow our design system.`}</p>
    <p>{`Plus a growing pile of pre-built components.`}</p>
    <p>{`The core component is a `}<inlineCode parentName="p">{`<Box>`}</inlineCode>{`. Like a div with extra skillz.`}</p>
    <p>{`You style any UI component through the `}<inlineCode parentName="p">{`sx`}</inlineCode>{` prop. It supports the usual CSS features you're used to, makes them theme-aware, and adds shorthand properties.`}</p>
    <p>{`For example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Box sx={{ p: 2 }} />
`}</code></pre>
    <p>{`Creates a Box with 4px padding. `}<inlineCode parentName="p">{`px: 2`}</inlineCode>{` adds left/right padding, `}<inlineCode parentName="p">{`py`}</inlineCode>{` adds top/bottom padding, `}<inlineCode parentName="p">{`pl`}</inlineCode>{` left padding, etc. Same approach for margins with `}<inlineCode parentName="p">{`m`}</inlineCode>{`.`}</p>
    <p>{`Values that don't map to a theme value are treated as raw CSS 🤘`}</p>
    <p>{`You can see our scales `}<a parentName="p" {...{
        "href": "https://5efa84bae8e40500223e8b9e-bnaqzlpuwp.chromatic.com/?path=/story/scales--colors"
      }}>{`on the storybook page for scales`}</a></p>
    <p><inlineCode parentName="p">{`sx`}</inlineCode>{` supports styling child components through your favorite CSS selectors. We'll skip that part today but you might find it in the code.`}</p>
    <h3 {...{
      "id": "responsive-design"
    }}>{`Responsive design`}</h3>
    <p>{`We use breakpoints for responsive design. Turn any `}<inlineCode parentName="p">{`sx`}</inlineCode>{` value into a 3-element array to change how it looks at different viewport sizes. Resize the window and look at the button:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Button
  sx={{
    bg: ["red", "secondary", "primary"],
    fontSize: [1, 2, 3],
    p: [2, 2, 3],
  }}
>
  I'm a button
</Button>
`}</code></pre>
    <Button sx={{
      bg: ["red", "secondary", "primary"],
      fontSize: [1, 2, 3],
      p: [2, 2, 3],
      mb: 3
    }} mdxType="Button">
  I'm a button
    </Button>
    <h2 {...{
      "id": "exercise"
    }}>{`Exercise`}</h2>
    <p>{`Take the `}<a parentName="p" {...{
        "href": "https://github.com/AskTia/tia-react-train"
      }}>{`starter repo`}</a>{` and use fake data from `}<inlineCode parentName="p">{`src/fakeDoggos.json`}</inlineCode>{` to render a homepage of doggos. Make it responsive so that you get a nice set of pictures at any screen size.`}</p>
    <p>{`Use the `}<inlineCode parentName="p">{`<Image>`}</inlineCode>{` component to render images. I suggest `}<a parentName="p" {...{
        "href": "https://theme-ui.com/components/grid"
      }}><inlineCode parentName="a">{`<Grid>`}</inlineCode></a>{` for the layout.`}</p>
    <h3 {...{
      "id": "solution"
    }}>{`Solution`}</h3>
    <p>{`You can find my solution at `}<a parentName="p" {...{
        "href": "https://github.com/AskTia/tia-react-train/tree/solution-tia-ui"
      }}>{`https://github.com/AskTia/tia-react-train/tree/solution-tia-ui`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      